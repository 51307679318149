<template>
  <div class="mb-5">
    <div class="pt-4 pr-4 pb-0 d-flex justify-content-center align-items-center">
      <validation-provider
          name="number-of-operators" rules="required|min_value:0" tag="div" v-slot="v" class="main-input"
          :customMessages="{min_value: 'This field must be 0 or more', required: 'This field is required'}">
        <b-form-group class="input-wrapper">
          <h5>Number of Operators</h5>
          <b-input-group>
            <b-input-group-prepend>
              <b-button variant="outline-info" @click="onDecrementClick">
                <i class="fas fa-minus"/>
              </b-button>
            </b-input-group-prepend>
            <b-form-input
                v-model="operatorsNum"
                class="operators-input"
                type="number"
                min="0"
                required
                :state="getState(v)"
                size="lg" @change="onNumberInputChange(v.errors)">
            </b-form-input>
            <b-input-group-append>
              <b-button variant="outline-info" @click="onIncrementClick">
                <i class="fas fa-plus"/>
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-form-invalid-feedback :state="getState(v)">
            {{ v.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <i class="fas fa-history history-button" @click="onHistoryClick"/>
    </div>
    <loader :show="loading" fullscreen/>
    <div v-if="!loading">
      <not-working :show="!computedCards.length && !returnedCards.length"/>
      <div v-if="computedCards.length" class="mb-4">
        <hr/>
        <h4 class="p-0 m-0 d-flex justify-content-center">New Collections</h4>
        <hr class="mb-4"/>
        <div class="row ml-2 mr-2 justify-content-center">
          <collection-card class="m-2 collection-card"
            v-for="card in computedCards"
            :key="card.uuid"
            :object="card"
            @onCardUpdate="onCardUpdate"
          />
        </div>
      </div>
      <div v-if="returnedCards.length">
        <hr/>
        <h4 class="p-0 m-0 d-flex justify-content-center">Returned Collections</h4>
        <hr class="mb-4"/>
        <div class="row ml-2 mr-2 justify-content-center">
          <collection-card class="m-2 collection-card"
            v-for="card in returnedCards" 
            :key="card.uuid" 
            :object="card"
            @onCardUpdate="onCardUpdate"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CollectionCard from '@/modules/Operator/CollectionCard';
import {mapActions, mapState} from 'pinia';
import {useOperatorStore} from '@/store/operator';
import {useSettingStore} from '@/store/settings';
import Loader from '@/core/components/loader/loader';
import NotWorking from '@/core/components/not-working/NotWorking';

export default {
  name: 'Operator',
  components: {NotWorking, Loader, CollectionCard},
  data() {
    return {
      operatorsNum: 0,
    }
  },
  computed: {
    ...mapState(useOperatorStore, ['prescriptionCards', 'loading']),
    ...mapState(useSettingStore, ['settings', 'settingsList']),
    computedCards() {
      return this.prescriptionCards.filter(pc => pc.returned_on === null).slice(0, this.operatorsNum);
    },
    returnedCards() {
      return this.prescriptionCards.filter(pc => pc.returned_on !== null);
    },
  },
  methods: {
    ...mapActions(useOperatorStore, ['getPrescriptions', 'updatePrescription', 'updatePrescriptionCards']),
    ...mapActions(useSettingStore, ['getSettings', 'getSettingsList', 'updateSetting']),
    onIncrementClick() {
      this.operatorsNum++;
      this.updateOperatorsSetting('number_of_operators', this.operatorsNum);
    },
    onDecrementClick() {
      if (this.operatorsNum > 0) {
        this.operatorsNum--;
        this.updateOperatorsSetting('number_of_operators', this.operatorsNum);
      }
    },
    onNumberInputChange(errors) {
      if (!errors.length) {
        this.updateOperatorsSetting('number_of_operators', this.operatorsNum);
      }
    },
    updateOperatorsSetting(key, value) {
      const setting = this.settings.find(s => s.key === key);
      if (Object.keys(setting).length) {
        this.updateSetting({uuid: setting.uuid, data: {...setting, value: value}});
      }
    },
    async onCardUpdate({uuid, data}) {
      if (data.current_status === 3) {
        // const confirmed = await this.$confirm('Are you sure you want to complete the card?', 'Please Confirm');
        // if (confirmed) {
        const {success} = await this.updatePrescription({uuid, data});
        if (success) {
          this.$toast('success', 'Card Completed Successfully');
        } else {
          this.$toast('danger', 'Unable to complete card');
        }
        // }
      } else {
        await this.updatePrescription({uuid, data});
      }
    },
    getState(validator) {
      if (validator.validated === true) {
        return validator.valid === true ? null : false;
      }
      if (!validator.touched) {
        return null;
      }
      return validator.valid === true ? null : false;
    },
    onHistoryClick() {
      this.$router.push({name: 'history'});
    },
  },
  async mounted() {
    this.getSettings();
    await this.getSettingsList();
    this.operatorsNum = this.settingsList.number_of_operators || 0;

    this.getPrescriptions();

    this.$ws.connect();
    this.$ws.listen('SEND_CARD_TO_OPERATORS', this.updatePrescriptionCards);
    this.$ws.listen('RETURN_CARD_TO_OPERATORS', this.updatePrescriptionCards);
  },
  destroyed() {
    this.$ws.remove('SEND_CARD_TO_OPERATORS');
    this.$ws.remove('RETURN_CARD_TO_OPERATORS');
  },
}
</script>

<style lang="scss" scoped>
.invalid-feedback {
  font-size: 12px;
}

.operators-input {
  border-bottom-color: #3989c6;
  border-top-color: #3989c6;
}

.operators-input:focus, .operators-input:active, .btn:focus, .btn:active {
  box-shadow: none !important;
}

.input-wrapper {
  min-width: 430px;
}

.collection-card {
  min-width: 430px;
}

.main-input {
  flex: 1;
  display: flex;
  justify-content: center;
  transform: translateX(9px);
}

.history-button {
  transform: translateX(-5px);
  cursor: pointer;
  font-size: 22px;
  color: #ffc107;
  height: 12px;
}
</style>