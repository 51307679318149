<template>
  <div class="page-container">
    <div v-if="operators" class="content">
      <slot name="top-nav"></slot>
      <div class="row">
        <div class="col-3">
          <h4 class="info-bar">Preparing...</h4>
          <div class="card-grid">
            <div
              class="collection-card flash"
              v-for="card in inProgressCards"
              :key="card.uuid"
            >
              {{ card.id_number }}
            </div>
          </div>
        </div>
        <div class="col-3">
          <h4 class="info-bar">Please Collect</h4>
          <div class="card-grid">
            <div
              class="collection-card flash"
              v-for="card in readyToHandoutCards"
              :key="card.uuid"
            >
              {{ card.id_number }}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="logo">
            <b-img height="100" src="/static/img/gompels_logo.png" />
          </div>
          <div class="additional-image">
            <img :src="dynamicImageUrl" />
          </div>
        </div>
      </div>

      <footer class="footer">
      Average collection time: {{ formattedAvgCollectionTime }} min
      </footer>

    </div>

    <div v-else class="disabled-message-wrapper">
      <not-working
        :show="true"
        text="Gompels Collect is not currently available, please join the queue"
      />
    </div>
  </div>
</template>

<script>
import NotWorking from "@/core/components/not-working/NotWorking.vue";

export default {
  name: "BaseStatusLayout",
  components: { NotWorking },
  props: {
    operators: { type: Boolean, default: false },
    inProgressCards: { type: Array, required: true },
    readyToHandoutCards: { type: Array, required: true },
    dynamicImageUrl: { type: String, default: "" },
    formattedAvgCollectionTime: { type: Number, default: 0 },
  },
};
</script>

<style scoped lang="scss">
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}

.disabled-message-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.row {
  display: flex;
  width: 100%;
}

.col-3 {
  width: 25%;
  padding-left: 0px;
  padding-right: 8px;
}

.col-6 {
  width: 50%;
  padding-left: 8px;
  padding-right: 8px;
}

.card-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.collection-card {
  font-size: calc(100% + 2.5vw);
  font-weight: bold;
  color: rgb(40, 40, 40);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #d6f7e0;
  border-radius: 8px;
}

.info-bar {
  background: #0a8544;
  color: white;
  font-size: calc(100% + 2.5vw);
  font-weight: bold;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.footer {
  background: #0a8544;
  color: white;
  font-size: calc(100% + 2.5vw);
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.additional-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
}
.additional-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

@keyframes flash {
  0% {
    background-color: #d6f7e0;
  }
  50% {
    background-color: #f9f9f9;
  }
  100% {
    background-color: #d6f7e0;
  }
}
.flash {
  animation: flash 0.2s ease-in-out 7;
}
</style>
