<template>
  <base-status-layout
    :operators="operators"
    :in-progress-cards="inProgressCards"
    :ready-to-handout-cards="readyToHandoutCards"
    :dynamic-image-url="dynamicImageUrl"
    :formatted-avg-collection-time="formattedAvgCollectionTime"
  >
    <template #top-nav>
      <nav class="media-nav">
        <div class="nav-title">
        </div>
      </nav>
    </template>
  </base-status-layout>
</template>

<script>
import BaseStatusLayout from "@/core/components/layout/BaseStatusLayout";
import { mapActions, mapState } from "pinia";
import { useOperatorStore } from "@/store/operator";
import { useSettingStore } from "@/store/settings";
import httpService from "@/core/services/httpService";

export default {
  name: "Status",
  components: { BaseStatusLayout },
  data() {
    return {
      operatorsNum: null,
      operators: null,
      avgCollectionTime: 60, //in seconds
      dynamicImageUrl: "",
    };
  },
  computed: {
    ...mapState(useOperatorStore, ["prescriptionCards"]),
    ...mapState(useSettingStore, ["settingsList"]),

    inProgressCards() {
      return this.prescriptionCards.filter((card) =>
        [0, "0", 1, "1"].includes(card.current_status)
      );
    },
    readyToHandoutCards() {
      return this.prescriptionCards.filter((card) =>
        [2, "2"].includes(card.current_status)
      );
    },
    formattedAvgCollectionTime() {
      return Math.ceil(this.avgCollectionTime / 60);
    },
  },
  methods: {
    ...mapActions(useOperatorStore, ["getPrescriptions"]),
    ...mapActions(useSettingStore, ["getSettingsList"]),

    handleStatusUpdate({ uuid, current_status }) {
      this.getPrescriptions();
      const updateCard = this.prescriptionCards.find((card) => card.uuid === uuid);
      if (updateCard) {
        this.$set(updateCard, "current_status", current_status);
      }
    },
    handleAvgCollectionTime(data) {
      if (data && data.average_collection_time > 0) {
        this.avgCollectionTime = data.average_collection_time;
      }
    },
    async fetchImageUrlFromBackend() {
      const { success, body } = await httpService.get("/api/v1/media/");
      if (success && body && body.image_url) {
        this.dynamicImageUrl = body.image_url;
      } else {
        this.dynamicImageUrl = "";
      }
    },
  },
  async mounted() {
    await this.getPrescriptions();
    await this.getSettingsList();
    await this.fetchImageUrlFromBackend();

    this.operatorsNum = this.settingsList.number_of_operators;
    this.operators = this.operatorsNum > 0;

    this.$ws.connect();
    this.$ws.listen("UPDATE_MEDIA_SETTINGS", (data) => {
      this.dynamicImageUrl = data.image_url || "";
    });
    this.$ws.listen("AVG_COLLECTION_TIME", this.handleAvgCollectionTime);
    this.$ws.listen("STATUS_CHANGE", this.handleStatusUpdate);
    this.$ws.listen("SEND_CARD_TO_OPERATORS", this.handleStatusUpdate);
    this.$ws.listen("CHANGE_NUMBER_OF_OPERATORS", (data) => {
      this.operatorsNum = data.number_of_operators;
      this.operators = this.operatorsNum > 0;
    });
  },
  destroyed() {
    this.$ws.remove("UPDATE_MEDIA_SETTINGS");
    this.$ws.remove("AVG_COLLECTION_TIME");
    this.$ws.remove("STATUS_CHANGE");
    this.$ws.remove("CHANGE_NUMBER_OF_OPERATORS");
    this.$ws.remove("SEND_CARD_TO_OPERATORS");
  },
};
</script>

<style scoped lang="scss">
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  cursor: none;
}

.media-nav {
  display: flex;
  align-items: center;
  background: #0a8544;
  padding: 10px;
  gap: 10px;
}
.nav-title {
  color: #fff;
  font-size: 18px;
  color:#fff;
  margin:0;
  font-size:18px;
}
</style>
