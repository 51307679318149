<template>
  <div class="main-layout">
    <div class="main">
      <div class="container">
        <div class="form-wrapper">
          <h4 class="text-center p-4">Barcode Collection - Please choose site</h4>
          <div class="d-flex pb-4">
            <b-card class="mr-2 site-button" no-body>
              <b-button variant="outline-success" size="lg" @click="onMediaSiteClick">Media Site</b-button>
            </b-card>
            <b-card class="mr-2 site-button" no-body>
              <b-button variant="outline-success" size="lg" @click="onPatientSiteClick">Patient Site</b-button>
            </b-card>
            <b-card class="mr-2 site-button" no-body>
              <b-button variant="outline-success" size="lg" @click="onOperatorSiteClick">Operator Site</b-button>
            </b-card>
            <b-card class="mr-2 site-button" no-body>
              <b-button variant="outline-success" size="lg" @click="onStatusSiteClick">Status Site</b-button>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainLayout',
  methods: {
    onPatientSiteClick() {
      this.$router.push({name: 'patients'});
    },
    onOperatorSiteClick() {
      this.$router.push({name: 'operators'});
    },
    onMediaSiteClick() {
      this.$router.push({name: 'media'});
    },
    onStatusSiteClick() {
      this.$router.push({name: 'status'});
    },
  },
}
</script>

<style lang="scss" scoped>

.main-layout {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main {
  width: 680px;
  margin: 0 auto;
  display: flex;
  align-items: center;


  .form-wrapper {
    padding: 25px;
    width: 100%;
    position: relative;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
    background: #fff;
  }
}

.site-button {
  width: 50%;
}

</style>