<template>
  <loader v-if="loading" :show="loading" fullscreen />
  <not-working
    v-else-if="!isWorkingHours || !operatorsNum"
    :show="true"
    text="Gompels Collect is not currently available, please join the queue"
  />
  <div v-else class="main-layout">
    <div class="main">
      <div class="container">
        <div class="form-wrapper pt-0">
          <div class="text-center">
            <b-img class="pt-3" height="100" src="/static/img/gompels_logo.png"/>
            <div v-if="isResponse && isCompletedPrescription" class="pb-4 pt-3">
              <h2 class="pt-3">
                Your prescription was collected on:
              </h2>
              <h1 class="pt-3 font-weight-bold collection-message">
                {{ completedOnFormatted }}
              </h1>
              <h4 class="pt-3">
                Please ask the staff if you have any questions.
              </h4>
            </div>
            <div v-else-if="isResponse" class="pb-4 pt-3">
              <h2 class="pt-3">
                Your Collection Number is: <br/>
              </h2>
              <h1 class="pt-3 font-weight-bold collection-number">
                {{ collectionNumber }}
              </h1>
              <h4 class="pt-3">
                Thanks for using Gompels Collect. <br/>
                Please take a seat and wait to be called.
              </h4>
            </div>
            <h4 v-else class="pb-4 pt-3">
              Welcome to Gompels, please scan or key in your collection code
              and a member of staff will get your prescription for you
            </h4>
          </div>
          <div v-if="isResponse" class="d-flex justify-content-center mb-4 mt-2">
            <b-card class="mr-2 site-button" no-body>
              <b-button variant="outline-success" size="lg" @click="onScanNewCodeClick">
                Scan New Code
              </b-button>
            </b-card>
          </div>
          <div v-else class="text-center mb-4 mt-2">
            <b-img alt="" width="150" height="150" src="/static/img/scan_img.png"/>
            <h4 class="m-0 pb-4 pt-4">OR</h4>
            <div class="d-flex justify-content-center">
              <b-input-group style="width: 50%">
                <b-form-input
                  class="form-control patient-input"
                  v-model="wroteBarcode"
                  placeholder="Write Code"
                  size="lg"
                  @input="tmpBarcode = ''"
                  @focus="removeScanBarcode"
                  @blur="addScanBarcode"
                  @keydown.enter="onSendClick(wroteBarcode)"
                />
                <b-input-group-append>
                  <b-button variant="outline-secondary" @click="onSendClick(wroteBarcode)">
                    <i class="fas fa-paper-plane"/>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'pinia';
import {useSettingStore } from '@/store/settings';
import {usePatientStore } from '@/store/patient';
import NotWorking from '@/core/components/not-working/NotWorking';
import Loader from '@/core/components/loader/loader';

export default {
  name: 'Patient',
  components: {Loader, NotWorking},
  data() {
    return {
      operatorsNum: null,
      scannedBarcode: '',
      wroteBarcode: '',
      isResponse: false,
      completedOn: null,
      collectionNumber: null,
      tmpBarcode: '',
      hasScannerListener: false,
      timer: null,
      isWorkingHours: true,
    };
  },
  computed: {
    ...mapState(useSettingStore, ['settingsList', 'loading']),
    isCompletedPrescription() {
      return !!this.completedOn;
    },
    completedOnFormatted() {
      if (!this.completedOn) return ''
      const dateObj = new Date(this.completedOn)
      const day = dateObj.getDate()
      const monthName = dateObj.toLocaleString('default', { month: 'long' })
      const year = dateObj.getFullYear()
      const hours = String(dateObj.getHours()).padStart(2, '0')
      const minutes = String(dateObj.getMinutes()).padStart(2, '0')
      return `${day} ${monthName} ${year}  ${hours}:${minutes}`
    }
  },
  methods: {
    ...mapActions(usePatientStore, ['createPrescription']),
    ...mapActions(useSettingStore, ['getSettingsList']),
    addScanBarcode() {
      if (!this.hasScannerListener) {
        document.addEventListener('keydown', this.onKeyPress);
      }
      this.hasScannerListener = true;
    },
    removeScanBarcode() {
      if (this.hasScannerListener) {
        document.removeEventListener('keydown', this.onKeyPress);
      }
      this.hasScannerListener = false;
    },
    async onSendClick(barcode) {
      if (!barcode) {
        return;
      }
      const {success, body} = await this.createPrescription({barcode: barcode.toUpperCase()});

      if (success) {
        if (body.current_status === '3') {
          this.isResponse = true;
          this.completedOn = body.completed_on;
        } else {
          this.isResponse = true;
          this.collectionNumber = body.id_number;
          this.completedOn = null
        }
        this.wroteBarcode = '';
        this.removeScanBarcode();
        this.timer = setTimeout(() => {
          this.isResponse = false;
          this.collectionNumber = null;
          this.addScanBarcode();
        }, 7000);
      } else {
        this.wroteBarcode = '';
        this.$toast('danger', body.barcode[0]);
      }
    },
    async onKeyPress($event) {
      if ($event.code === 'Enter') {
        if (this.tmpBarcode) {
          const hasPrefix = this.tmpBarcode.slice(0, 1) === '$';
          this.scannedBarcode = hasPrefix ? this.tmpBarcode.slice(3, this.tmpBarcode.length) : this.tmpBarcode;
          await this.onSendClick(this.scannedBarcode);
        }
        this.tmpBarcode = '';
        return;
      }
      if (($event.which >= 48 && $event.which <= 90) || ($event.which >= 96 && $event.which <= 105)) {
        this.tmpBarcode += $event.key;
        this.wroteBarcode = this.tmpBarcode;
      }
    },
    onScanNewCodeClick() {
      this.isResponse = false;
      this.collectionNumber = null;
      this.addScanBarcode();
      clearTimeout(this.timer);
    },
    checkWorkingHours() {
      const now = new Date();
      const hour = now.getHours();
      const minute = now.getMinutes();
      this.isWorkingHours = !(hour < 9 || hour > 17 || (hour === 17 && minute >= 30));
    },
  },
  async mounted() {
    await this.getSettingsList();
    this.operatorsNum = this.settingsList.number_of_operators;

    this.checkWorkingHours();

    setInterval(() => {
      this.checkWorkingHours();
    }, 60 * 1000); // in milliseconds

    if (this.operatorsNum > 0 && this.isWorkingHours) {
      this.addScanBarcode();
    }

    this.$ws.connect();
    this.$ws.listen('CHANGE_NUMBER_OF_OPERATORS', (data) => {
      this.operatorsNum = data.number_of_operators;
      if (this.operatorsNum > 0 && this.isWorkingHours) {
        this.addScanBarcode();
      } else {
        this.removeScanBarcode();
      }
    });
  },
  destroyed() {
    this.$ws.remove('CHANGE_NUMBER_OF_OPERATORS');
  },
}
</script>

<style lang="scss" scoped>
.main-layout {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main {
  width: 680px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  
  .form-wrapper {
    padding: 25px;
    width: 100%;
    position: relative;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
    background: #fff;
  }
}

.site-button {
  width: 50%;
}

.patient-input {
  text-transform: uppercase;
}

.patient-input::placeholder {
  text-transform: none;
}

.patient-input:focus, .patient-input:active, .btn:focus, .btn:active {
  box-shadow: none !important;
}

.collection-number {
  color: #0A8544;
  font-size: 10rem;
}

.collection-message {
  color: #0A8544;
  font-size: 5rem;
}
</style>
