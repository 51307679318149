import Vue from 'vue'
import VueRouter from 'vue-router'
import MainLayout from '@/core/components/layout/MainLayout';
import Operator from '@/modules/Operator/Operator';
import Patient from '@/modules/Patient/Patient';
import CollectionHistory from '@/modules/Operator/CollectionHistory';
import Status from '@/modules/Status/Status';
import Media from '@/modules/Media/Media';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'main',
      component: MainLayout,
      meta: {guest: true},
    },
    {
      path: '/operators',
      name: 'operators',
      component: Operator,
      meta: {guest: true},
    },
    {
      path: '/status',
      name: 'status',
      component: Status,
      meta: {guest: true},
    },
    {
      path: '/patients',
      name: 'patients',
      component: Patient,
      meta: {guest: true},
    },
    {
      path: '/media',
      name: 'media',
      component: Media,
      meta: {guest: true},
    },
    {
      path: '/history',
      name: 'history',
      component: CollectionHistory,
      meta: {guest: true},
    }
  ]
})

export default router;