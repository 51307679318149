<template>
  <base-status-layout
    :operators="true"
    :in-progress-cards="inProgressCards"
    :ready-to-handout-cards="readyToHandoutCards"
    :dynamic-image-url="dynamicImageUrl"
    :formatted-avg-collection-time="formattedAvgCollectionTime"
  >
    <template #top-nav>
      <nav class="media-nav">
        <div class="input-wrapper">
          <input
            v-model="inputImageUrl"
            class="url-input"
            :class="{ 'url-invalid': !isUrlValid && inputImageUrl.trim() !== '' }"
            placeholder="http://www.example.com/image.jpg"
            @input="onInputChange"
          />
          <div
            v-if="showPlaceholderMsg && inputImageUrl.trim() === ''"
            class="placeholder-hint"
          >
            Example format: https://myserver.com/foo.png
          </div>
        </div>
        <div class="button-group">
          <button @click="updateImageUrl" :disabled="!isUrlValid">Submit</button>
          <button @click="resetImageUrl">Reset</button>
        </div>
      </nav>
    </template>
  </base-status-layout>
</template>

<script>
import BaseStatusLayout from "@/core/components/layout/BaseStatusLayout";
import { mapActions, mapState } from "pinia";
import { useOperatorStore } from "@/store/operator";
import { useSettingStore } from "@/store/settings";
import httpService from "@/core/services/httpService";

export default {
  name: "Media",
  components: { BaseStatusLayout },
  data() {
    return {
      inputImageUrl: "",
      isUrlValid: true,
      showPlaceholderMsg: true,
      operatorsNum: null,
      operators: null,
      avgCollectionTime: 60, // in seconds
      dynamicImageUrl: "",
    };
  },
  computed: {
    ...mapState(useOperatorStore, ["prescriptionCards"]),
    ...mapState(useSettingStore, ["settingsList"]),
    inProgressCards() {
      return this.prescriptionCards.filter((card) =>
        [0, "0", 1, "1"].includes(card.current_status)
      );
    },
    readyToHandoutCards() {
      return this.prescriptionCards.filter((card) =>
        [2, "2"].includes(card.current_status)
      );
    },
    formattedAvgCollectionTime() {
      return Math.ceil(this.avgCollectionTime / 60);
    },
  },
  methods: {
    ...mapActions(useOperatorStore, ["getPrescriptions"]),
    ...mapActions(useSettingStore, ["getSettingsList"]),
    onInputChange() {
      this.showPlaceholderMsg = false;
      this.validateUrl();
    },
    validateUrl() {
      const urlPattern = new RegExp(
        "^(https?://)" +
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
          "((\\d{1,3}\\.){3}\\d{1,3}))" +
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+@]*)*" +
          "(\\?[;&a-z\\d%_.~+=-]*)?" +
          "(\\#[-a-z\\d_]*)?$",
        "i"
      );
      if (this.inputImageUrl.trim() === "") {
        this.isUrlValid = true;
        return;
      }
      const passUrlPattern = !!this.inputImageUrl.match(urlPattern);
      const passImageExt = !!this.inputImageUrl;
      this.isUrlValid = passUrlPattern && passImageExt;
    },
    async updateImageUrl() {
      if (!this.isUrlValid) return;
      await httpService.post("/api/v1/media/", { image_url: this.inputImageUrl });
    },
    async resetImageUrl() {
      this.inputImageUrl = "";
      this.isUrlValid = true;
      this.showPlaceholderMsg = true;
      await httpService.post("/api/v1/media/", { image_url: "" });
    },
    handleStatusUpdate({ uuid, current_status }) {
      this.getPrescriptions();
      const updateCard = this.prescriptionCards.find((card) => card.uuid === uuid);
      if (updateCard) {
        this.$set(updateCard, "current_status", current_status);
      }
    },
    handleAvgCollectionTime(data) {
      if (data && data.average_collection_time > 0) {
        this.avgCollectionTime = data.average_collection_time;
      }
    },
    async fetchImageUrlFromBackend() {
      const { success, body } = await httpService.get("/api/v1/media/");
      if (success && body && body.image_url) {
        this.dynamicImageUrl = body.image_url;
      } else {
        this.dynamicImageUrl = "";
      }
    },
  },
  async mounted() {
    await this.getPrescriptions();
    await this.getSettingsList();
    await this.fetchImageUrlFromBackend();
    this.operatorsNum = this.settingsList.number_of_operators;
    this.operators = this.operatorsNum > 0;
    this.$ws.connect();
    this.$ws.listen("UPDATE_MEDIA_SETTINGS", (data) => {
      this.dynamicImageUrl = data.image_url || "";
    });
    this.$ws.listen("AVG_COLLECTION_TIME", this.handleAvgCollectionTime);
    this.$ws.listen("STATUS_CHANGE", this.handleStatusUpdate);
    this.$ws.listen("SEND_CARD_TO_OPERATORS", this.handleStatusUpdate);
    this.$ws.listen("CHANGE_NUMBER_OF_OPERATORS", (data) => {
      this.operatorsNum = data.number_of_operators;
      this.operators = this.operatorsNum > 0;
    });
  },
  destroyed() {
    this.$ws.remove("UPDATE_MEDIA_SETTINGS");
    this.$ws.remove("AVG_COLLECTION_TIME");
    this.$ws.remove("STATUS_CHANGE");
    this.$ws.remove("CHANGE_NUMBER_OF_OPERATORS");
    this.$ws.remove("SEND_CARD_TO_OPERATORS");
  },
};
</script>

<style scoped lang="scss">
.media-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.media-nav {
  display: flex;
  align-items: center;
  background: #0a8544;
  padding: 10px;
  gap: 10px;
}
.input-wrapper {
  position: relative;
  flex: 1;
}
.placeholder-hint {
  position: absolute;
  top: 7px;
  left: 12px;
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.7);
}
.url-input {
  width: 100%;
  padding: 6px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.url-invalid {
  border-color: red;
  color: red;
}
.button-group {
  display: flex;
  gap: 8px;
}
.content {
  flex: 1;
  padding: 10px 15px;
}
.row {
  display: flex;
  width: 100%;
}
.col-3 {
  width: 25%;
  padding-left: 0px;
  padding-right: 8px;
}
.col-6 {
  width: 50%;
  padding-left: 8px;
  padding-right: 8px;
}
.card-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.collection-card {
  font-size: calc(100% + 2.5vw);
  font-weight: bold;
  color: rgb(40, 40, 40);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #d6f7e0;
  border-radius: 8px;
}
.info-bar {
  background: #0a8544;
  color: white;
  font-size: calc(100% + 2.5vw);
  font-weight: bold;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
.footer {
  background: #0a8544;
  color: white;
  font-size: calc(100% + 2.5vw);
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.additional-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
}
.additional-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
@keyframes flash {
  0% { background-color: #d6f7e0; }
  50% { background-color: #f9f9f9; }
  100% { background-color: #d6f7e0; }
}
.flash {
  animation: flash 0.2s ease-in-out 7;
}
</style>
